<template>
    <div :style="`overflow: scroll; ${background ? `background-color: ${background};` : ''} padding-top: 8px;`">
        <v-container style="height: 87vh; max-width: 900px; -webkit-overflow-scrolling:touch;" :class="`scroll-hide-festy ${$vuetify.breakpoint.name == 'xs' ? 'pt-0 pl-5 pr-5' : ''}`" color="#f1f1f1" v-if="store">
        <v-row align="center"> <!-- justify="center" style="border: 1px black; border-style: solid;" -->
            <v-col cols="12" sm="2" style="text-align: center;" class="pa-0">
                <v-avatar color="yellow darken-2" size="100" style="border: 3px solid;">
                   <img id="logoStore" ref="logoStore" :src="store.attributes.logoUrl" alt="">
                </v-avatar>
            </v-col>

            <v-col cols="12" sm="10" class="pt-1">
                <h3 class="mb-3">
                    {{ store.attributes.name }}
                    <v-chip
                        class="ml-1 mr-2"
                        :color="store.attributes.status == 'active' ? 'success' : ''"
                        :text-color="store.attributes.status == 'active' ? 'white' : 'black'"
                        small
                        label
                        style="vertical-align: middle;"
                        >
                        <v-avatar left>
                            <v-icon v-if="store.attributes.status == 'active'">mdi-lock-open-outline</v-icon>
                            <v-icon v-else>mdi-lock-outline</v-icon>
                        </v-avatar>
                        {{ store.attributes.status == 'active' ? 'ABIERTO' : 'CERRADO' }}
                    </v-chip>
                </h3>

                <!--<p class="body-2 black--text mb-1" v-if="!descriptionComplete">
                    {{store.attributes.description.slice(0, 120)}}
                    <span v-if="store.attributes.description.length > 120">....</span>
                    <a @click="readMore()" v-if="store.attributes.description.length > 120"> Ver más</a>
                </p>

                <p class="body-2 black--text mb-1" v-else>
                    {{store.attributes.description}}
                    <a @click="readMore()"> Ver menos</a>
                </p>-->
                
                <div style="border: 1px black; border-style: solid; border-radius: 6px;" class="pa-1">
                    <p class="body-2 black--text mb-2" v-if="store.attributes.description">
                        <v-icon small left>mdi-information</v-icon><a @click="dialogDescriptionStore = true">Saber más</a>
                    </p>

                    <p class="body-2 black--text mb-2" v-if="store.attributes.acceptBankTransfer">
                        <v-icon small left>mdi-wallet</v-icon>Acepta pagos por: <strong>Efectivo, {{ getStringTransferMethods(store.attributes.transfersMethods) }}</strong>
                    </p>
                    
                    <p class="body-2 black--text mb-2">
                        <v-icon small left>mdi-map-marker</v-icon>{{ store.attributes.address }}, {{ store.attributes.city }}
                    </p>

                    <p class="body-2 black--text mb-2" v-if="store.attributes.delivery && store.attributes.delivery.timeCaption">
                        <v-icon left small>mdi-bike</v-icon> <strong>{{ store.attributes.delivery.timeCaption }}</strong>
                    </p>

                    <p class="body-2 black--text mb-0" v-if="store.attributes.rating" @click="dialogStoreReview = true">
                        <v-icon small left>mdi-star-circle</v-icon>
                        <strong>({{store.attributes.rating}})</strong>
                        <v-rating
                            style="display: contents !important;"
                            v-model="store.attributes.rating"
                            background-color="grey"
                            color="yellow accent-4"
                            dense
                            hover
                            size="18"
                            readonly
                        >
                        </v-rating>
                    </p>
                </div>

            </v-col>

        </v-row>

        <v-row v-if="$store.state.products && $store.state.products.length > 0">
            <v-col cols="12" class="pl-0 pr-0 pb-0 pt-0" v-if="categories.length > 0">
                <v-sheet
                    class="mx-auto align-content-center"
                    :color="background ? background : '#f1f1f1'"
                >
                    <!--<v-slide-group show-arrows center-active mandatory>
                    <v-slide-item
                        v-for="(item, index) in categories" :key="index"
                        v-slot:default="{ active, toggle }"
                    >
                        <v-btn
                        class="mx-1"
                        :input-value="active"
                        active-class="purple white--text"
                        dark
                        depressed
                        rounded
                        small
                        @click="toggle"
                        @click.native="changeCategoryProductsStore(item)"
                        >
                        <v-icon v-if="active" left color="white">mdi-check-circle</v-icon>
                        <strong>{{ item }}</strong>
                        </v-btn>
                    </v-slide-item>
                    </v-slide-group>-->

                    <v-tabs 
                    :background-color="background ? background : '#f1f1f1'"
                    :color="colorText ? colorText : 'black'"
                    left
                    centered
                    height="40"
                    >
                        <v-tab 
                        v-for="(item, index) in categories" :key="index"
                        class="font-weight-bold"
                        @click="scrollToElement(item)"
                        >
                            <strong>{{ item }}</strong>
                        </v-tab>
                    </v-tabs>
                </v-sheet>
            </v-col>

            <v-col cols="12" v-if="$store.state.products && $store.state.products.length > 0" class="pt-0 mt-0 pb-0 mb-0">
                <v-row v-for="(item, index) in $store.state.products" :key="index" class="pa-2 pb-0 mb-0">
                    <v-col cols="12" v-if="item.attributes.products && item.attributes.products.length > 0" class="pa-1 pt-0 mt-0 mb-0 pb-0 d-inline-block text-truncate">
                        <section :class="`${item.attributes.name} pa-0 ma-0 text-uppercase body-2`">
                        <strong
                            style="vertical-align: -webkit-baseline-middle;"
                            :class="$vuetify.breakpoint.name == 'xs' ? 'body-2 font-weight-bold' : ''"
                        >
                            {{item.attributes.name}}
                        </strong>
                        </section>
                    </v-col>
                    <v-col
                        v-for="(promo, index2) in item.attributes.products"
                        :key="index2"
                        class="d-flex child-flex pa-1"
                        cols="4"
                        sm="4"
                        md="3"
                    >

                    <v-hover :key="index2">
                        <template v-slot:default="{ hover }">
                        <v-card class="align-end" @click="openDialogAddItem(promo)" v-lazy:background-image="promo.attributes.imageUrl" :style="`background-size: cover; background-position: center center; background-repeat: no-repeat;`" :height="$vuetify.breakpoint.name == 'xs' ? '100' : '150'">
                      
                            <v-card-text class="pa-0 ma-0" v-if="promo.attributes.exclusive">
                                <v-chip
                                class="ml-0 mt-2 font-weight-bold"
                                style="top: 0; left: 0; position: relative; border-radius: 0 !important;"
                                small
                                color="error"
                                label      
                                >
                                Exclusivo
                                </v-chip>
                            </v-card-text>

                            <v-card-text
                                class="pa-0 white--text align-content-end"
                                style="position: absolute; bottom: 0; left: 0; max-width: 100%; background-image: linear-gradient(rgba(33, 33, 33, 0.8), rgba(33, 33, 33, 0.8)); background-position: center center;"
                            >
                                <v-row class="pl-1 pr-1" align="center" justify="center">
                                <v-col cols="12" class="pl-3 pt-0 pb-0 d-inline-block text-truncate">
                                    <strong :style="$vuetify.breakpoint.name == 'xs' ? 'font-size: 12px;' : ''">
                                    {{ promo.attributes.name }}
                                    </strong>
                                    <div :style="$vuetify.breakpoint.name == 'xs' ? 'font-size: 12px;' : ''" class="font-weight-bold">
                                    <span class="font-weight-black">
                                        {{ promo.attributes.newPrice ? promo.attributes.newPrice : promo.attributes.price | currency }}
                                    </span>
                                    <span class="text-decoration-line-through" v-if="promo.attributes.newPrice" >
                                        ({{ promo.attributes.price | currency }})
                                    </span>
                                    </div>
                                </v-col>
                                </v-row>
                            </v-card-text>
                        
                            <v-fade-transition>
                            <v-overlay
                                v-if="hover"
                                absolute
                                color="yellow"
                            >
                                <v-btn @click="openDialogAddItem(promo)"><v-icon >mdi-cart-plus</v-icon> <span v-if="$vuetify.breakpoint.name != 'xs'" class="pl-1">Agregar</span> </v-btn>
                            </v-overlay>
                            </v-fade-transition>

                        </v-card>
                                
                        </template>
                    </v-hover>
                    </v-col>
                </v-row>
            </v-col>

            <!--
            <v-col cols="12" sm="12" class="mb-10 pt-0">
            <div>
                
                <v-row>
                    <v-col
                        v-for="(promo, index2) in $store.state.products"
                        :key="index2"
                        :class="`d-flex child-flex ${$vuetify.breakpoint.name == 'xs' ? 'pa-1' : ''}`"
                        cols="4"
                        sm="4"
                        md="3"
                    >

                    <v-hover :key="index2">
                        <template v-slot:default="{ hover }">
                        <v-card class="d-flex align-end" @click="openDialogAddItem(promo)" v-lazy:background-image="promo.attributes.imageUrl" :style="`background-size: cover; background-position: center center; background-repeat: no-repeat;`" :height="$vuetify.breakpoint.name == 'xs' ? '100' : '150'">
                            <v-card-text
                                class="pa-0 white--text align-content-end"
                                style="max-width: 100%; background-image: linear-gradient(rgba(33, 33, 33, 0.8), rgba(33, 33, 33, 0.8)); background-position: center center;"
                            >
                                <v-row class="pl-1 pr-1" align="center" justify="center">
                                <v-col cols="12" class="pl-3 pt-0 pb-0 d-inline-block text-truncate">
                                    <strong :style="$vuetify.breakpoint.name == 'xs' ? 'font-size: 12px;' : ''">
                                    {{ promo.attributes.name }}
                                    <br />
                                    {{ promo.attributes.price | currency }}
                                    </strong>
                                </v-col>
                                </v-row>
                            </v-card-text>
                                            
                            <v-fade-transition>
                            <v-overlay
                                v-if="hover"
                                absolute
                                color="yellow"
                            >
                                <v-btn @click="openDialogAddItem(promo)"><v-icon >mdi-cart-plus</v-icon> <span v-if="$vuetify.breakpoint.name != 'xs'" class="pl-1">Agregar</span> </v-btn>
                            </v-overlay>
                            </v-fade-transition>

                        </v-card>
                                
                        </template>
                    </v-hover>
                    </v-col>
                </v-row>
                
            </div>
            </v-col>-->
        </v-row>

        <v-dialog
            v-model="dialogAddItems"
            max-width="600"
            scrollable
            v-if="promotion"
            :no-click-animation="true"
        >
        <v-card class="mx-auto">

            <v-card-title class="subtitle-1 d-inline-block text-truncate pb-1">
            <v-btn class="float-right" icon @click="dialogAddItems = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            {{ promotion.attributes.name }}
            </v-card-title>

            <v-card-subtitle class="subtitle-2 pb-2">
            {{ promotion.attributes.store.attributes.name }}
            </v-card-subtitle>

            <v-img
                aspect-ratio="1.7778"
                v-if="promotion.attributes.imageUrl"
                :lazy="promotion.attributes.imageUrl"
                :src="promotion.attributes.imageUrl"
            ></v-img>

            <v-card-text>
            <v-row>
                <v-col cols="7">
                <div class="headline"> {{ promotion.attributes.newPrice ? promotion.attributes.newPrice : promotion.attributes.price | currency }} </div>
                <div class="subtitle-2"> DOMICILIO: {{ promotion.attributes.deliveryCost | currency }} </div>
                </v-col>
                <v-col cols="5" style="text-align: right; padding-right: 0px;">
                    <div>
                        <div class="subtitle-2" style="margin-right: 15px;"> <v-icon>mdi-share</v-icon> Compartir </div>
                        <ShareNetwork
                            network="facebook"
                            :url="getUrlSharing(promotion.id)"
                            :title="promotion.attributes.name"
                            :description="promotion.attributes.description"
                            :quote="promotion.attributes.description"
                            hashtags="festyapp,promos,promociones"
                            style="margin-right: 10px;"
                        >
                            <v-icon style="color: #1877f2;">mdi-facebook</v-icon>
                        </ShareNetwork>

                        <ShareNetwork
                            network="twitter"
                            :url="getUrlSharing(promotion.id)"
                            :title="promotion.attributes.name"
                            :description="promotion.attributes.description"
                            :quote="promotion.attributes.description"
                            hashtags="festyapp,promos,promociones"
                            style="margin-right: 10px;"
                        >
                            <v-icon style="color: #1da1f2;">mdi-twitter</v-icon>
                        </ShareNetwork>

                        <ShareNetwork
                            network="whatsapp"
                            :url="getUrlSharing(promotion.id)"
                            :title="promotion.attributes.name"
                            :description="promotion.attributes.description"
                            :quote="promotion.attributes.description"
                            hashtags="festyapp,promos,promociones"
                            style="margin-right: 10px;"
                        >
                            <v-icon style="color: #25d366;">mdi-whatsapp</v-icon>
                        </ShareNetwork>

                    </div>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                {{ promotion.attributes.description }}
                </v-col>

                <v-col cols="12" class="pt-0 pb-0" v-if="promotion.attributes.modifiers && promotion.attributes.modifiers.length > 0">
                    <v-row>
                    <v-col cols="12" v-for="(item, index) in promotion.attributes.modifiers" :key="index">
                        <div class="body-1"> <strong>{{ item.title }}</strong> <span class="caption info--text font-weight-bold">{{ item.mandatory ? '(modificador obligatorio)' : '' }}</span> </div>
                        <div class="body-1 ma-0 pa-0" v-if="!item.multiple">
                        <v-radio-group v-model="itemsBox[item.title]" hide-details class="pa-1 ma-0" row>
                            <v-radio
                                v-for="(option, index2) in item.options" :key="index2"
                                :label="`${option.label}`"
                                :value="option"
                                @change="setModifiersMirrorBox(item, option)"
                            >
                            <template v-slot:label>
                                {{ option.label }} <strong class="ml-2 caption font-weight-bold"> +{{ option.price > 0 ? option.price : 0 | currency}}</strong>
                            </template>
                            </v-radio>
                        </v-radio-group>
                        </div>
                        <div class="body-1 ma-0 pa-0" justify="space-around" v-else>
                        <v-checkbox
                            class="ma-1"
                            v-for="(option, index2) in item.options" :key="index2"
                            v-model="itemsCheck"
                            hide-details
                            :value="option"
                            @change="setModifiersMirrorCheck(item)"
                            >
                            <template v-slot:label>
                            {{ option.label }} <strong class="ml-2 caption font-weight-bold"> +{{ option.price > 0 ? option.price : 0 | currency}}</strong>
                            </template>
                            </v-checkbox>
                        </div>
                    </v-col>
                    </v-row>
                </v-col>

                
            </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn tile small color="grey" text @click="removeQuantity()" :disabled="quantity <= 1">
                <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
            </v-btn>

            <div class="pr-2 pl-2"><strong>{{ quantity }}</strong></div>

            <v-btn tile small color="grey" text @click="addQuantity()">
                <v-icon color="success">mdi-plus-circle-outline</v-icon>
            </v-btn>

            <v-btn
                color="success darken-1"
                @click="addPromoToCar()"
            >
                <v-icon>mdi-cart-plus</v-icon>
                <span class="ml-2 font-weight-bold d-flex d-md-none">Agregar</span>
            <span class="ml-2 font-weight-bold d-none d-md-flex">Agregar {{ quantity * (promotion.attributes.newPrice ? promotion.attributes.newPrice : promotion.attributes.price + selectModifiers.reduce((a, b) => { return a + b.options.reduce((c, d) => { return c + d.price}, 0) }, 0) ) | currency }}</span>
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- Dialogo review store -->
        <v-dialog v-model="dialogStoreReview" width="500" scrollable>
        <v-form ref="formCancel" v-model="validCancel">
            <v-card v-if="ratings">
            <v-card-title class="subtitle-1 grey lighten-2" primary-title v-if="store.attributes.name">
                {{ store.attributes.name }} <span v-if="store.attributes.rating" class="pl-1"> ({{store.attributes.rating}})</span>
            </v-card-title>

            <v-card-text v-if="ratings && ratings.length > 0">
                <div class="mb-2"></div>
                
                <v-list three-line v-if="ratings">
                <template>
                    
                    <!--<v-subheader>
                        Premedio: XX
                    </v-subheader>-->

                    <span v-for="(item, index) in ratings" :key="index">

                    <v-divider v-if="item.rating"></v-divider>

                    <v-list-item
                    v-if="item.rating"
                    :key="index"
                    >
                    <!--<v-list-item-avatar>
                        <v-img :src="item.attributes.owner.attributes.photoUrl ? item.attributes.owner.attributes.photoUrl : require('./../assets/user.png')"></v-img>
                    </v-list-item-avatar>-->

                    <v-list-item-content>
                        <v-list-item-title class="body-2">
                            <div>{{item.alias}}</div>
                        </v-list-item-title>
                        <v-list-item-subtitle style="display: flex;">
                            <v-rating
                                :value="item.rating"
                                background-color="grey lighten-2"
                                color="yellow accent-4"
                                class="pa-0 ma-0"
                                dense
                                hover
                                size="18"
                                readonly
                            ></v-rating>
                            <span class="black--text text--lighten-2 caption ml-1">
                                ({{ item.rating }})
                            </span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                        {{ item.comments ? item.comments : 'Sin comentario.' }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                    </span>
                </template>
                </v-list>

            </v-card-text>
                
                <v-card-text v-else>
                    El comercio no tiene calificaciones aún.
                </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn color="primary" outlined @click="dialogStoreReview = false">
                <v-icon left>mdi-close</v-icon>Cerrar
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            </v-card>
        </v-form>
        </v-dialog>
        <!-- *End* Dialogo review store -->

        <!-- Dialogo review store -->
        <v-dialog v-model="dialogDescriptionStore" width="400" scrollable>
        <v-form ref="formCancel" v-model="validCancel">
            <v-card>
            <v-card-title class="title" primary-title>
                Información
            </v-card-title>

            <v-card-text v-if="store.attributes.description">
                <div class="mb-2"></div>
                {{store.attributes.description}}
            </v-card-text>
                
            <v-divider></v-divider>

            <v-card-actions>
                <v-btn color="primary" outlined @click="dialogDescriptionStore = false">
                <v-icon left>mdi-close</v-icon>Cerrar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" outlined @click="dialogDescriptionStore = false, dialogStoreReview = true">
                <v-icon left>mdi-star-circle</v-icon>Comentarios
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-form>
        </v-dialog>
        <!-- *End* Dialogo review store -->

        <div class="text-center" v-if="loading">
            <v-progress-circular
            :size="70"
            color="amber"
            indeterminate
            ></v-progress-circular>
        </div>

        </v-container>

        <h1 v-else-if="!loading" class="text-center pt-5">No encontramos el comercio que buscas. 😬</h1>
        
    </div>
</template>

<script>
import Parse from 'parse'
//import Vibrant from 'node-vibrant'

export default {
    metaInfo() {
        return {
            meta: this.meta
        }
    },

    data() {
        return {
            store: '',
            loading: true,
            meta: [],
            description: '',
            descriptionComplete: false,
            dialogAddItems: false,
            promotion: '',
            quantity: 1,
            modifiers: '',
            comboOption: '',
            comboPrice: '',
            selectModifiers: [],
            itemsBox: [],
            itemsCheck: [],
            categories: [],
            background: '',
            colorText: '',
            colorTextTitle: '',
            dialogStoreReview: false,
            validCancel: false,
            ratings: [],
            dialogDescriptionStore: false
        }
    },

    async mounted() {
        this.$store.state.searchProductBox = true
        await this.initialize();
        await this.getProducts();
        await this.getStoreReviewsList()
        
        this.meta = [
            {
                vmid: this.store ? this.store.attributes.description : '',
                name: this.store ? this.store.attributes.name : '',
                content: this.store ? this.store.attributes.description : ''
            }
        ]

        if (this.$route.query.id) {
            this.openModalProductById(this.$route.query.id)
        }
        //window.hj('stateChange', this.$route.path)
        
    },

    beforeDestroy(){
        this.$store.state.searchProductBox = false
    },

    methods: {
        async initialize () {
            let params = {
                id: this.$route.params.store_id
            }
            try {
                let store = await Parse.Cloud.run("getPublicStoreBO", params);
                this.store = store[0]
                //eslint-disable-next-line
                //console.log(store);
                //this.loading = false
                this.addViewToStore(this.store)
                //this.setColorProfilePage(this.store.attributes.logoUrl);
            
            } catch (error) {
                this.$snotify.error(`${error}` , 'Comercio', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
            
        },

        async getProducts () {
            
            this.loading = true

            let params = {
                id: this.$route.params.store_id,
                storeId: this.store.id
            }
            try {
                let products = await Parse.Cloud.run("getStoreProducts", params);
                //let products = await Parse.Cloud.run("getPublicProductsBO", params);
                this.$store.commit("setProducts", products);
                this.$store.commit("setAllProducts", products);
                
                //eslint-disable-next-line
                //console.log(this.$store.state.all_products);
                this.getCategoriesPromos()
                this.loading = false
               
            } catch (error) {
                this.$snotify.error(`${error}` , 'Productos', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
            
        },

        async getStoreReviewsList () {

            let params = {
                storeId: this.store.id
            }
            try {
                let ratings = await Parse.Cloud.run("getStoreReviewsList", params);
                this.ratings = ratings
                //eslint-disable-next-line
                //console.log(ratings);
               
            } catch (error) {
                this.$snotify.error(`${error}` , 'Calificaciones', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
            
        },

        async addViewToStore(store){
            try {
                const params = {
                    storeId: store.id
                };
                await Parse.Cloud.run("viewStore", params);
            
            } catch (error) {
                //eslint-disable-next-line
                console.log(error);
            }
        },

        readMore(){
            this.descriptionComplete = !this.descriptionComplete
        },

        getStringTransferMethods(methods){
            if (methods && methods.length > 0) { 
                let string_methods = ''   
                for (let index = 0; index < methods.length; index++) {
                    const element = methods[index];
                    string_methods += element.name

                    if (index < methods.length - 1) {
                        string_methods += ', '
                    }
                }

                return string_methods
            }
        },

        setModifiersMirrorCheck(item){
            //eslint-disable-next-line
            //console.log(this.itemsCheck);

            var cleanOptions = item.options.filter((word) => this.itemsCheck.includes(word));
            //console.log(cleanOptions);

            if (cleanOptions.length <= 0) {
                let index = this.selectModifiers.findIndex((element) => item.title === element.title )
                this.selectModifiers.splice(index, 1)
                return;
            }

            let newItem = {
                mandatory: item.mandatory,
                multiple: item.multiple,
                options: cleanOptions,
                title: item.title
            }
            let checkItem = this.selectModifiers.find((element) => item.title === element.title )
            if (checkItem) {
                let index = this.selectModifiers.findIndex((element) => item.title === element.title )
                this.selectModifiers[index] = newItem
            }else{
                this.selectModifiers.push(newItem) 
            }

            //eslint-disable-next-line
            //console.log(this.selectModifiers);
        },

        setModifiersMirrorBox(item, option){ 
            let newItem = {
                mandatory: item.mandatory,
                multiple: item.multiple,
                options: [option],
                title: item.title
            }
            let checkItem = this.selectModifiers.find((element) => item.title === element.title )
            if (checkItem) {
                let index = this.selectModifiers.findIndex((element) => item.title === element.title )
                this.selectModifiers[index] = newItem
            }else{
                this.selectModifiers.push(newItem) 
            }

            //eslint-disable-next-line
            //console.log(this.selectModifiers);
        },

        getBreakPointCol(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 2
                case 'sm': return 3
                case 'md': return 3
                case 'lg': return 3
                case 'xl': return 3
            }
        },

         openDialogAddItem(promo){
            //eslint-disable-next-line
            this.promotion = ''
            this.promotion = promo
            this.quantity = 1
            this.modifiers = ''
            this.comboOption = ''
            this.comboPrice = ''
            this.dialogAddItems = true
            this.selectModifiers = []
            this.itemsCheck = []
            this.itemsBox = []
        },

        addQuantity(){
            this.quantity = this.quantity+1
        },

        removeQuantity(){
            if (this.quantity > 1) {
                this.quantity = this.quantity-1
            }
        },

        addPromoToCar(){

            if(!Parse.User.current()){
                this.$router.push('/login')
                return;
            }

            if (!window.localStorage){
                this.$snotify.error('Su navegador no soporta el almacenamiento local.' , 'LocalStorage', {
                timeout: 4000,
                preventDuplicates: true
                });
                return;
            }

            if (this.$store.state.cart && this.$store.state.cart.length > 0) {
                if (this.$store.state.cart[0].store !== this.promotion.attributes.store.id) {
                this.$swal('Multiples comercios', `Ya tienes productos de ${this.$store.state.cart[0].storeName} en el carrito de compras, primero debes finalizar tu pedido con ellos para agregar productos de otro comercio.`, 'info');
                return;
                } 
            }

            if (this.promotion.attributes.modifiers && this.promotion.attributes.modifiers.length > 0) {
                let self = this
                let cleanOptions = this.promotion.attributes.modifiers.filter((o1) => {
                if(o1.mandatory){
                    return !self.selectModifiers || self.selectModifiers.length <= 0 || !self.selectModifiers.find((element) => o1.title === element.title )
                }
                }, self);

                if (cleanOptions.length > 0) {
                this.$snotify.html(`<div class="snotifyToast__title"><b>Modificadores</b></div><div class="snotifyToast__body">Seleccione los modificadores <strong>${cleanOptions.map(u => u.title).join(', ')}</strong> del producto para poder agregarlo al carrito</div><div class="snotify-icon snotify-icon--info"></div>`, {
                    timeout: 7000,
                    preventDuplicates: true,
                    type: 'info'
                });
                return;
                }
            
            }
            
            let cartItem = {
                product: this.promotion.id,
                quantity: this.quantity,
                price: this.promotion.attributes.newPrice ? this.promotion.attributes.newPrice : this.promotion.attributes.price,
                name: this.promotion.attributes.name,
                withCombo: false, //this.comboOption
                comboPrice: this.promotion.attributes.comboPrice,
                comboNotes: this.promotion.attributes.comboNotes,
                store: this.promotion.attributes.store.id,
                storeName: this.promotion.attributes.store.attributes.name,
                minAmount: this.promotion.attributes.store.attributes.minAmount,
                logoUrl: this.promotion.attributes.store.attributes.logoUrl,
                imageUrl: this.promotion.attributes.imageUrl,
                description: '',
                modifiers: this.selectModifiers,
                modifiersText: this.modifiersDetails(this.selectModifiers, false),
                modifiersPrice: this.selectModifiers.reduce((a, b) => { return a + b.options.reduce((c, d) => { return c + d.price}, 0) }, 0),
                deliveryCost: this.promotion.attributes.deliveryCost
            }
            
            this.$store.commit("pushCart", cartItem);
            window.localStorage.setItem("cartFestyApp", JSON.stringify(this.$store.state.cart));
            window.localStorage.setItem("paymentFestyApp", JSON.stringify(this.promotion.attributes.store.attributes.payment));

            if (this.promotion.attributes.store.attributes.acceptBankTransfer && this.promotion.attributes.store.attributes.transfersMethods.length > 0) {
                window.localStorage.setItem("storePaymentsMethodsFestyApp", JSON.stringify(this.promotion.attributes.store.attributes.transfersMethods));
            }else{
                window.localStorage.setItem("storePaymentsMethodsFestyApp", JSON.stringify([]));
            }

            if (this.promotion.attributes.store.attributes.hasCardReader) {
                window.localStorage.setItem("storePaymentsHasCardReader", 1);
            }else{
                window.localStorage.setItem("storePaymentsHasCardReader", '');
            }

            this.dialogAddItems = false
        },

        modifiersDetails(modifiers, slice_text){
        let pedido_text = ''
        let pedido_text_temp = ''
        modifiers.forEach((item, indx) => {
            if (item.options) {
            item.options.forEach((option, index) => {
                pedido_text += `${option.label}`
                if (index+1 < item.options.length) {            
                pedido_text += ' | '
                }
            })

            if (indx+1 < modifiers.length) {            
                pedido_text += ' | '
                }
            }
        })

        pedido_text_temp = pedido_text

        if (slice_text) {
            pedido_text_temp = pedido_text.substring(0, 150)
            if (pedido_text.length > 150) {
            pedido_text_temp += '....'
            }
        }

        return pedido_text_temp
        },

        changeCategoryProductsStore(item) {
            if (item == "Todas") {
                this.$store.state.products = this.$store.state.all_products
            } else {
                let products = this.$store.state.all_products.filter(product => product.attributes.category === item)
                this.$store.state.products = products;
            }
        },

        getCategoriesPromos(){
            if (this.$store.state.all_products && this.$store.state.all_products.length > 0) {
                let categories = this.$store.state.all_products.map(o => o.get("name"))
                categories = Array.from(new Set(categories))
                categories = categories.filter(Boolean);
                //categories.unshift('Todas')
                this.categories = categories
            }
        },

        scrollToElement(item) {
            const el = this.$el.getElementsByClassName(item)[0];
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        },

        getUrlSharing(id){
            return `${window.location.origin}${window.location.pathname}?id=${id}` 
        },

        openModalProductById(id){
            let product = ''
            for (let index = 0; index < this.$store.state.products.length; index++) {
                const category = this.$store.state.all_products[index];
                product = category.attributes.products.find(element => element.id == id);
                if (product) {
                    break;
                }
            }
            
            if (product) {
                this.openDialogAddItem(product)
            }
        }

        /*setColorProfilePage(img){
            if (img) {
                Vibrant.from(img).getPalette((err, palette) => {
                    //console.log(palette)
                    //this.background = palette.LightVibrant.hex
                    //this.colorText = palette.LightVibrant.bodyTextColor
                    //this.colorTextTitle = palette.LightVibrant.titleTextColor
                });
            }
        }*/
    },

}
</script>

<style lang="css">
    .scroll-hide-festy::-webkit-scrollbar {
        display: none;
    }

    .v-slide-group__prev{
        display: none !important;
    }

    .share-network-facebook{
        text-decoration: none;
    }
    
    .share-network-twitter{
        text-decoration: none;
    }

    .share-network-whatsapp{
        text-decoration: none;
    }
</style>